import requestService from '../../requestService';
import cookieService from './../../cookieService';
import jwtTokenService from '../../jwtTokenService';
import axios from 'axios';

export class SalesPackageService {
  static async getSalesPackageTypeList() {
    const path = '/rest/sales-package/sales-package-type';

    return requestService.makeGetRequest(path);
  }

  static async getSalesPackageFieldsMetadata(salesPackageTypeId) {
    const path = '/rest/sales-package/sales-package-fields-metadata?salesPackageTypeId=' + salesPackageTypeId;

    return requestService.makeGetRequest(path);
  }

  static async getSalesPackageSettlementMethods() {
    const path = '/rest/sales-package/settlement-method';

    return requestService.makeGetRequest(path);
  }

  static async getPartnerList() {
    const path = '/rest/sales-package/partners';

    return requestService.makeGetRequest(path);
  }

  static async getPartnerData(partnerId) {
    const path = process.env.VUE_APP_DOMAIN + '/rest/sales-package/partner/id/' + partnerId;

    return requestService.makeGetRequest(path);
  }

  static async getPartnerTypeList() {
    const path = process.env.VUE_APP_DOMAIN + '/rest/sales-package/partner-type';

    return requestService.makeGetRequest(path);
  }

  static async addPartner(
    partnerName = null,
    partnerTypeId = null,
    website = null,
    countryId = null,
    commission = null,
    currencyId = null
  ) {
    const token = cookieService.getCookie('token');
    const config = {
      headers: {
        Authorization: token
      }
    };

    const userId = jwtTokenService.getUserIdFromJwtToken(token);

    return await axios.post(
      process.env.VUE_APP_DOMAIN + '/rest/sales-package/partner',
      {
        partnerName: partnerName,
        partnerTypeId: partnerTypeId,
        website: website,
        countryId: countryId,
        commission: commission,
        currencyId: currencyId,
        ownerId: userId
      },
      config
    );
  }

  static async modifyPartner(
    partnerId,
    partnerName = null,
    partnerTypeId = null,
    website = null,
    countryId = null,
    commission = null,
    currencyId = null
  ) {
    const token = cookieService.getCookie('token');
    const config = {
      headers: {
        Authorization: token
      }
    };

    return await axios.put(
      process.env.VUE_APP_DOMAIN + '/rest/sales-package/partner',
      {
        partnerId: partnerId,
        partnerName: partnerName,
        partnerTypeId: partnerTypeId,
        website: website,
        countryId: countryId,
        commission: commission,
        currencyId: currencyId
      },
      config
    );
  }

  static async createAdditionalCodes(
    id,
    codes,
    numberOfCodesToGenerate
  ) {
    const token = cookieService.getCookie('token');
    const config = {
      headers: {
        Authorization: token
      }
    };

    return await axios.put(
      process.env.VUE_APP_DOMAIN + '/rest/sales-package/sales-package',
      {
        id: id,
        codes: codes,
        numberOfCodesToGenerate: numberOfCodesToGenerate
      },
      config
    );
  }

  static async saveSalesPackage(
    id,
    name,
    salesPackageTypeId,
    partnerId,
    settlementMethodId = null,
    numberOfAccessDays = null,
    unitPrice = null,
    packagePrice = null,
    percentageDiscount = null,
    commission = null,
    dealLink = null,
    validFrom = null,
    validTo = null,
    publicationFrom = null,
    publicationTo = null,
    codeUsageLimit = null
  ) {
    const token = cookieService.getCookie('token');
    const config = {
      headers: {
        Authorization: token
      }
    };

    if (id) {
      return await axios.put(
        process.env.VUE_APP_DOMAIN + '/rest/sales-package/sales-package',
        {
          id: id,
          name: name,
          salesPackageTypeId: salesPackageTypeId,
          partnerId: partnerId,
          settlementMethodId: settlementMethodId,
          numberOfAccessDays: numberOfAccessDays,
          unitPrice: unitPrice,
          packagePrice: packagePrice,
          percentageDiscount: percentageDiscount,
          commission: commission,
          dealLink: dealLink,
          validFrom: validFrom,
          validTo: validTo,
          publicationFrom: publicationFrom,
          publicationTo: publicationTo,
          codeUsageLimit: codeUsageLimit
        },
        config
      );
    }

    return await axios.post(
      process.env.VUE_APP_DOMAIN + '/rest/sales-package/sales-package',
      {
        name: name,
        salesPackageTypeId: salesPackageTypeId,
        partnerId: partnerId,
        settlementMethodId: settlementMethodId,
        numberOfAccessDays: numberOfAccessDays,
        unitPrice: unitPrice,
        packagePrice: packagePrice,
        percentageDiscount: percentageDiscount,
        commission: commission,
        dealLink: dealLink,
        validFrom: validFrom,
        validTo: validTo,
        publicationFrom: publicationFrom,
        publicationTo: publicationTo,
        codeUsageLimit: codeUsageLimit
      },
      config
    );
  }

  static async getSalesPackageDataById(salesPackageId) {
    const path = process.env.VUE_APP_DOMAIN + '/rest/sales-package/sales-package/id/' + salesPackageId;

    return requestService.makeGetRequest(path);
  }

  static async getSalesPackageDataByName(salesPackageName) {
    const path = process.env.VUE_APP_DOMAIN + '/rest/sales-package/sales-package/name/' + salesPackageName;

    return requestService.makeGetRequest(path);
  }

  static async getSalesPackageList() {
    const path = process.env.VUE_APP_DOMAIN + '/rest/sales-package/sales-packages';

    return requestService.makeGetRequest(path);
  }

  static async getCodes(salesPackageId, codeType, outputFormat = 'csv') {
    const path = process.env.VUE_APP_DOMAIN +
      '/rest/sales-package/code/list?outputFormat=' +
      outputFormat +
      '&codeType=' +
      codeType +
      '&salesPackageId=' +
      salesPackageId;

    return requestService.makeGetRequest(path);
  }

  static async getCodesUsedBySpecificUser(userId) {
    const path = process.env.VUE_APP_DOMAIN + '/rest/sales-package/code/list/user?userId=' + userId;

    return requestService.makeGetRequest(path);
  }
}

const salesPackageService = SalesPackageService;
export default salesPackageService;
