<template>
  <div class="row">
    <div class="col-md-6">
      <p>Pakiet sprzedażowy</p>
      <template v-if="salesPackagesResult">
        <template v-if="salesPackagesResult.isSuccess">
          <div class="alert alert-success" role="alert">
            {{salesPackagesResult.message}}
          </div>
        </template>
        <template v-else>
          <div class="alert alert-danger" role="alert">
            {{salesPackagesResult.message}}
          </div>
        </template>
      </template>
      <form @submit.prevent="saveChanges()">
        <p><b>Pola wymagane</b></p>
        <hr>

        <template v-if="salesPackageData.id">
          <div class="form-group row">
            <label for="id" class="col-sm-2 col-form-label">ID:</label>
            <div class="col-sm-10">
              <input class="form-control" id="id" v-model="salesPackageData.id" placeholder="edit me" readonly>
            </div>
          </div>
        </template>

        <div class="form-group row">
          <label for="name" class="col-sm-2 col-form-label">Nazwa:</label>
          <div class="col-sm-10">
            <input class="form-control" id="name" v-model="salesPackageData.name" placeholder="edit me">
          </div>
        </div>

        <div class="form-group row">
          <label for="salesPackageType" class="col-sm-2 col-form-label">Typ pakietu:</label>
          <div class="col-sm-10">
            <select name="salesPackageType" id="salesPackageType" v-model="salesPackageData.typeId"
                    v-on:click="getSalesPackageTypeMetadata(salesPackageData.typeId);
                  changeSalesPackageTypeInSelect()">
              <template v-for="salesPackageType in salesPackageTypeList" v-bind:key="salesPackageType.id">
                <template v-if="salesPackageData.id">
                  <option v-bind:value="salesPackageType.id" v-bind:disabled="'disabled'">{{salesPackageType.type}}</option>
                </template>
                <template v-else>
                  <option v-bind:value="salesPackageType.id">{{salesPackageType.type}}</option>
                </template>
              </template>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label for="salesPackageType" class="col-sm-2 col-form-label">Partner:</label>
          <div class="col-sm-10">
            <select name="salesPackageType" id="partner" v-model="salesPackageData.partnerId">
              <template v-for="partner in partnerList" v-bind:key="partner.id">
                <option v-bind:value="partner.id">{{partner.name}}</option>
              </template>
            </select>
          </div>
        </div>

        <template v-if="(isFieldRequired('settlementMethodId') !== null) && isFieldRequired('settlementMethodId')">
          <div class="form-group row">
            <label for="settlementMethod" class="col-sm-2 col-form-label">Metoda rozliczenia:</label>
            <div class="col-sm-10">
              <select name="settlementMethod" id="settlementMethod" v-model="salesPackageData.settlementMethodId">
                <template v-for="settlementMethod in salesPackageSettlementMethods" v-bind:key="settlementMethod.id">
                  <option v-bind:value="settlementMethod.id">{{settlementMethod.name}}</option>
                </template>
              </select>
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('unitPrice') !== null) && isFieldRequired('unitPrice')">
          <div class="form-group row">
            <label for="unitPrice" class="col-sm-2 col-form-label">Cena jednostkowa:</label>
            <div class="col-sm-10">
              <input class="form-control" id="unitPrice" v-model="salesPackageData.unitPrice" placeholder="">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('dealLink') !== null) && isFieldRequired('dealLink')">
          <div class="form-group row">
            <label for="dealLink" class="col-sm-2 col-form-label">Link do deal'a:</label>
            <div class="col-sm-10">
              <input class="form-control" id="dealLink" v-model="salesPackageData.dealLink" placeholder="">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('packagePrice') !== null) && isFieldRequired('packagePrice')">
          <div class="form-group row">
            <label for="packagePrice" class="col-sm-2 col-form-label">Cena pakietu:</label>
            <div class="col-sm-10">
              <input class="form-control" id="packagePrice" v-model="salesPackageData.packagePrice" placeholder="">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('commission') !== null) && isFieldRequired('commission')">
          <div class="form-group row">
            <label for="unitPrice" class="col-sm-2 col-form-label">Prowizja:</label>
            <div class="col-sm-10">
              <input class="form-control" id="commission" v-model="salesPackageData.commission" placeholder="">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('numberOfAccessDays') !== null) && isFieldRequired('numberOfAccessDays')">
          <div class="form-group row">
            <label for="numberOfAccessDays" class="col-sm-2 col-form-label">Liczba dni dostępu:</label>
            <div class="col-sm-10">
              <input class="form-control" id="numberOfAccessDays" v-model="salesPackageData.numberOfAccessDays" placeholder="">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('percentageDiscount') !== null) && isFieldRequired('percentageDiscount')">
          <div class="form-group row">
            <label for="percentageDiscount" class="col-sm-2 col-form-label">Zniżka procentowa:</label>
            <div class="col-sm-10">
              <input class="form-control" id="percentageDiscount" v-model="salesPackageData.percentageDiscount" placeholder="">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('publicationFrom') !== null) && isFieldRequired('publicationFrom')">
          <div class="form-group row">
            <label for="publicationFrom" class="col-sm-2 col-form-label">Publikacja od:</label>
            <div class="col-sm-10">
              <input class="form-control" id="publicationFrom" v-model="salesPackageData.publicationFrom"
                     placeholder="Datę i czas podaj w formacie: YYYY-MM-DD HH:MM:SS, np. 2022-02-18 23:34:11">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('publicationTo') !== null) && isFieldRequired('publicationTo')">
          <div class="form-group row">
            <label for="publicationTo" class="col-sm-2 col-form-label">Publikacja do:</label>
            <div class="col-sm-10">
              <input class="form-control" id="publicationTo" v-model="salesPackageData.publicationTo"
                     placeholder="Datę i czas podaj w formacie: YYYY-MM-DD HH:MM:SS, np. 2022-02-18 23:34:11">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('validFrom') !== null) && isFieldRequired('validFrom')">
          <div class="form-group row">
            <label for="validFrom" class="col-sm-2 col-form-label">Ważne od:</label>
            <div class="col-sm-10">
              <input class="form-control" id="validFrom" v-model="salesPackageData.validFrom"
                     placeholder="Datę i czas podaj w formacie: YYYY-MM-DD HH:MM:SS, np. 2022-02-18 23:34:11">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('validTo') !== null) && isFieldRequired('validTo')">
          <div class="form-group row">
            <label for="validTo" class="col-sm-2 col-form-label">Ważne do:</label>
            <div class="col-sm-10">
              <input class="form-control" id="validTo" v-model="salesPackageData.validTo"
                     placeholder="Datę i czas podaj w formacie: YYYY-MM-DD HH:MM:SS, np. 2022-02-18 23:34:11">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('info') !== null) && isFieldRequired('info')">
          <div class="form-group row">
            <label for="info" class="col-sm-2 col-form-label">Info:</label>
            <div class="col-sm-10">
              <input class="form-control" id="info" v-model="salesPackageData.info" placeholder="">
            </div>
          </div>
        </template>

        <p><b>Pola opcjonalne</b></p>
        <hr>

        <template v-if="(isFieldRequired('codeUsageLimit') !== null) && !isFieldRequired('codeUsageLimit')">
          <div class="form-group row">
            <label for="codeUsageLimit" class="col-sm-2 col-form-label">Limit użyć kodu:</label>
            <div class="col-sm-10">
              <input class="form-control" id="codeUsageLimit" v-model="salesPackageData.codeUsageLimit" placeholder="">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('settlementMethodId') !== null) && !isFieldRequired('settlementMethodId')">
          <div class="form-group row">
            <label for="settlementMethod" class="col-sm-2 col-form-label">Metoda rozliczenia:</label>
            <div class="col-sm-10">
              <select name="settlementMethod" id="settlementMethod" v-model="salesPackageData.settlementMethodId">
                <template v-for="settlementMethod in salesPackageSettlementMethods" v-bind:key="settlementMethod.id">
                  <option v-bind:value="settlementMethod.id">{{settlementMethod.name}}</option>
                </template>
              </select>
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('unitPrice') !== null) && !isFieldRequired('unitPrice')">
          <div class="form-group row">
            <label for="unitPrice" class="col-sm-2 col-form-label">Cena jednostkowa:</label>
            <div class="col-sm-10">
              <input class="form-control" id="unitPrice" v-model="salesPackageData.unitPrice" placeholder="">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('dealLink') !== null) && !isFieldRequired('dealLink')">
          <div class="form-group row">
            <label for="dealLink" class="col-sm-2 col-form-label">Link do deal'a:</label>
            <div class="col-sm-10">
              <input class="form-control" id="dealLink" v-model="salesPackageData.dealLink" placeholder="">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('packagePrice') !== null) && !isFieldRequired('packagePrice')">
          <div class="form-group row">
            <label for="packagePrice" class="col-sm-2 col-form-label">Cena pakietu:</label>
            <div class="col-sm-10">
              <input class="form-control" id="packagePrice" v-model="salesPackageData.packagePrice" placeholder="">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('commission') !== null) && !isFieldRequired('commission')">
          <div class="form-group row">
            <label for="unitPrice" class="col-sm-2 col-form-label">Prowizja:</label>
            <div class="col-sm-10">
              <input class="form-control" id="commission" v-model="salesPackageData.commission" placeholder="">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('numberOfAccessDays') !== null) && !isFieldRequired('numberOfAccessDays')">
          <div class="form-group row">
            <label for="numberOfAccessDays" class="col-sm-2 col-form-label">Liczba dni dostępu:</label>
            <div class="col-sm-10">
              <input class="form-control" id="numberOfAccessDays" v-model="salesPackageData.numberOfAccessDays" placeholder="">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('percentageDiscount') !== null) && !isFieldRequired('percentageDiscount')">
          <div class="form-group row">
            <label for="percentageDiscount" class="col-sm-2 col-form-label">Zniżka procentowa:</label>
            <div class="col-sm-10">
              <input class="form-control" id="percentageDiscount" v-model="salesPackageData.percentageDiscount" placeholder="">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('publicationFrom') !== null) && !isFieldRequired('publicationFrom')">
          <div class="form-group row">
            <label for="publicationFrom" class="col-sm-2 col-form-label">Publikacja od:</label>
            <div class="col-sm-10">
              <input class="form-control" id="publicationFrom" v-model="salesPackageData.publicationFrom" placeholder="Datę podaj w formacie: 2022-02-18">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('publicationTo') !== null) && !isFieldRequired('publicationTo')">
          <div class="form-group row">
            <label for="publicationTo" class="col-sm-2 col-form-label">Publikacja do:</label>
            <div class="col-sm-10">
              <input class="form-control" id="publicationTo" v-model="salesPackageData.publicationTo" placeholder="Datę podaj w formacie: 2022-02-18">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('validFrom') !== null) && !isFieldRequired('validFrom')">
          <div class="form-group row">
            <label for="validFrom" class="col-sm-2 col-form-label">Ważne od:</label>
            <div class="col-sm-10">
              <input class="form-control" id="validFrom" v-model="salesPackageData.validFrom" placeholder="Datę podaj w formacie: 2022-02-18">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('validTo') !== null) && !isFieldRequired('validTo')">
          <div class="form-group row">
            <label for="validTo" class="col-sm-2 col-form-label">Ważne do:</label>
            <div class="col-sm-10">
              <input class="form-control" id="validTo" v-model="salesPackageData.validTo" placeholder="Datę podaj w formacie: 2022-02-18">
            </div>
          </div>
        </template>

        <template v-if="(isFieldRequired('info') !== null) && !isFieldRequired('info')">
          <div class="form-group row">
            <label for="info" class="col-sm-2 col-form-label">Info:</label>
            <div class="col-sm-10">
              <input class="form-control" id="info" v-model="salesPackageData.info" placeholder="">
            </div>
          </div>
        </template>
        <br>
        <button type="submit" class="btn btn-primary">
          <template v-if="salesPackageData.id">
            Zapisz zmiany
          </template>
          <template v-else>
            Stwórz pakiet
          </template>
        </button>
      </form>
    </div>
    <div class="col-md-6">
      <template v-if="salesPackageData.id">
        <h3>Kody</h3><br/><br/>
        <p>Liczba użytych kodów: {{numberOfUsedCodes}}</p>
        <p>Liczba wszystkich kodów: {{numberOfAllCodes}}</p>
        <br/><br/>
        <button v-on:click="getAllCodes()">Pobierz wszystkie kody</button><br/>
        <button v-on:click="getUsedCodes()">Pobierz użyte kody</button><br/>
        <button v-on:click="getUnusedCodes()">Pobierz nieużyte kody</button><br/>
        <button v-on:click="manageCodes()">Zarządzaj kodami</button><br/>
      </template>
    </div>
  </div>
</template>

<script>
import salesPackageService from '../../../services/admin/SalesPackage/salesPackageService';
import salesPackagePartnerService from '../../../services/admin/SalesPackage/salesPackagePartnerService';
import salesPackageCodeService from '../../../services/admin/SalesPackage/salesPackageCodeService';

export default {
  name: 'SalesPackageNew',
  data() {
    return {
      salesPackageData: {
        id: null,
        name: null,
        typeId: null,
        partnerId: null,
        settlementMethodId: null,
        numberOfAccessDays: null,
        unitPrice: null,
        packagePrice: null,
        percentageDiscount: null,
        commission: null,
        dealLink: null,
        validFrom: null,
        validTo: null,
        publicationFrom: null,
        publicationTo: null,
        codeUsageLimit: null
      },
      salesPackageTypeMetadata: null,
      salesPackageSettlementMethods: null,
      salesPackageTypeList: null,
      partnerList: null,
      selected: null,
      selectedPartnerType: null,
      salesPackagesResult: null,
      salesPackageId: null,
      numberOfUsedCodes: null,
      numberOfAllCodes: null
    };
  },
  created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        this.salesPackageId = toParams.id ?? null;

        if (this.salesPackageId) {
          this.displaySalesPackageData();
          this.getSalesPackageTypeMetadata(this.salesPackageData.typeId);
          this.changeSalesPackageTypeInSelect();
          this.getNumberOfUsedCodes();
          this.getNumberOfAllCodes();
        } else {
          this.resetSalesPackageData();
          this.resetResultMessage();
        }
      }
    );
  },
  mounted() {
    this.initializeForm();
  },
  methods: {
    async initializeForm() {
      const routeParams = this.$route.params;
      this.salesPackageId = routeParams.id ?? null;

      if (this.salesPackageId) {
        this.salesPackageId = routeParams.id;
        await this.displaySalesPackageData();
        await this.getNumberOfUsedCodes();
        await this.getNumberOfAllCodes();
      }

      const salesPackageTypeId = this.salesPackageData.typeId ?? 1;

      await this.getSalesPackageTypeList();
      await this.getPartnerList();
      await this.getSalesPackageTypeMetadata(salesPackageTypeId);
      await this.getSalesPackageSettlementMethods();
      await this.changeSalesPackageTypeInSelect();
    },
    async getSalesPackageTypeList() {
      const response = await salesPackageService.getSalesPackageTypeList();
      this.salesPackageTypeList = response.data.salesPackageTypes.items;
    },
    async getPartnerList() {
      const response = await salesPackagePartnerService.getPartnerList();
      this.partnerList = response.data.partners.items;
    },
    async getSalesPackageTypeMetadata(salesPackageTypeId = 1) {
      const response = await salesPackageService.getSalesPackageFieldsMetadata(salesPackageTypeId);
      this.salesPackageTypeMetadata = response.data.salesPackageFieldsMetadata.items;
    },
    async getSalesPackageSettlementMethods() {
      const response = await salesPackageService.getSalesPackageSettlementMethods();
      this.salesPackageSettlementMethods = response.data.salesPackageSettlementMethods.items;
    },
    isFieldRequired(fieldName) {
      if (this.salesPackageTypeMetadata !== null) {
        for (const field of this.salesPackageTypeMetadata) {
          if (field.name === fieldName) {
            return field.isRequired;
          }
        }
      }

      return null;
    },
    changeSalesPackageTypeInSelect() {
      const selectedSalesPackageTypeId = this.salesPackageData.typeId;

      for (const salesPackageType of this.salesPackageTypeList) {
        if (selectedSalesPackageTypeId === salesPackageType.id) {
          this.selectedPartnerType = salesPackageType.type;
        }
      }
    },
    async displaySalesPackageData() {
      const response = await salesPackageService.getSalesPackageDataById(this.salesPackageId);
      const salesPackage = response.data.salesPackage.items[0];

      this.salesPackageData.id = salesPackage.id;
      this.salesPackageData.name = salesPackage.name;

      const type = salesPackage.type;
      this.salesPackageData.typeId = type ? type.id : null;

      const partner = salesPackage.partner;
      this.salesPackageData.partnerId = partner ? salesPackage.partner.id : null;

      const settlementMethod = salesPackage.settlementMethod;
      this.salesPackageData.settlementMethodId = settlementMethod ? settlementMethod.id : null;

      this.salesPackageData.numberOfAccessDays = salesPackage.numberOfAccessDays;
      this.salesPackageData.unitPrice = salesPackage.unitPrice;
      this.salesPackageData.packagePrice = salesPackage.packagePrice;
      this.salesPackageData.percentageDiscount = salesPackage.percentageDiscount;
      this.salesPackageData.commission = salesPackage.commission;
      this.salesPackageData.dealLink = salesPackage.dealLink;
      this.salesPackageData.validFrom = salesPackage.validFrom;
      this.salesPackageData.validTo = salesPackage.validTo;
      this.salesPackageData.publicationFrom = salesPackage.publicationFrom;
      this.salesPackageData.publicationTo = salesPackage.publicationTo;
      this.salesPackageData.codeUsageLimit = salesPackage.codeUsageLimit;
    },
    async saveChanges() {
      const response = await salesPackageService.saveSalesPackage(
        this.salesPackageData.id,
        this.salesPackageData.name,
        this.salesPackageData.typeId,
        this.salesPackageData.partnerId,
        this.salesPackageData.settlementMethodId,
        this.salesPackageData.numberOfAccessDays,
        this.salesPackageData.unitPrice,
        this.salesPackageData.packagePrice,
        this.salesPackageData.percentageDiscount,
        this.salesPackageData.commission,
        this.salesPackageData.dealLink,
        this.salesPackageData.validFrom,
        this.salesPackageData.validTo,
        this.salesPackageData.publicationFrom,
        this.salesPackageData.publicationTo,
        this.salesPackageData.codeUsageLimit
      );

      this.salesPackagesResult = response.data.salesPackages;

      if (this.salesPackagesResult.isSuccess) {
        this.salesPackageData.id = this.salesPackagesResult.items[0].id;
      }

      await this.$router.push('/admin/sales-package/' + this.salesPackageData.id);
    },
    async manageCodes() {
      await this.$router.push('/admin/sales-package/' + this.salesPackageData.id + '/codes');
    },
    resetSalesPackageData() {
      this.salesPackageData.id = null;
      this.salesPackageData.name = null;
      this.salesPackageData.typeId = null;
      this.salesPackageData.partnerId = null;
      this.salesPackageData.settlementMethodId = null;
      this.salesPackageData.numberOfAccessDays = null;
      this.salesPackageData.unitPrice = null;
      this.salesPackageData.packagePrice = null;
      this.salesPackageData.percentageDiscount = null;
      this.salesPackageData.commission = null;
      this.salesPackageData.dealLink = null;
      this.salesPackageData.validFrom = null;
      this.salesPackageData.validTo = null;
      this.salesPackageData.publicationFrom = null;
      this.salesPackageData.publicationTo = null;
      this.salesPackageData.codeUsageLimit = null;
    },
    resetResultMessage() {
      this.salesPackagesResult = null;
    },
    async getAllCodes() {
      const response = await salesPackageService.getCodes(this.salesPackageData.id, 'all');
      const filename = 'sales-package-' + this.salesPackageData.name + '-all-codes';
      const data = response.data;

      this.generateFile(data, filename);
    },
    async getUsedCodes() {
      const response = await salesPackageService.getCodes(this.salesPackageData.id, 'used');
      const filename = 'sales-package-' + this.salesPackageData.name + '-used-codes';
      const data = response.data;

      this.generateFile(data, filename);
    },
    async getUnusedCodes() {
      const response = await salesPackageService.getCodes(this.salesPackageData.id, 'unused');
      const filename = 'sales-package-' + this.salesPackageData.name + '-unused-codes';
      const data = response.data;

      this.generateFile(data, filename);
    },
    async getNumberOfUsedCodes() {
      const response = await salesPackageCodeService.getNumberOfCodes(this.salesPackageData.id, 'used');
      const salesPackageCode = response.data.salesPackageCode;
      this.numberOfUsedCodes = salesPackageCode.totalCount;
    },
    async getNumberOfAllCodes() {
      const response = await salesPackageCodeService.getNumberOfCodes(this.salesPackageData.id, 'all');
      const salesPackageCode = response.data.salesPackageCode;
      this.numberOfAllCodes = salesPackageCode.totalCount;
    },
    generateFile(data, filename) {
      const blob = new Blob([data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    }
  }
};
</script>

<style scoped>

</style>
