import requestService from '../../requestService';
import cookieService from './../../cookieService';
import jwtTokenService from '../../jwtTokenService';
import axios from 'axios';

export class SalesPackagePartnerService {
  static async getPartnerList() {
    const path = '/rest/sales-package/partners';

    return requestService.makeGetRequest(path);
  }

  static async getPartnerData(partnerId) {
    const path = '/rest/sales-package/partner/id/' + partnerId;

    return requestService.makeGetRequest(path);
  }

  static async getPartnerTypeList() {
    const path = '/rest/sales-package/partner-type';

    return requestService.makeGetRequest(path);
  }

  static async addPartner(
    partnerName = null,
    partnerTypeId = null,
    website = null,
    countryId = null,
    commission = null,
    currencyId = null
  ) {
    const token = cookieService.getCookie('token');
    const config = {
      headers: {
        Authorization: token
      }
    };

    const userId = jwtTokenService.getUserIdFromJwtToken(token);

    return await axios.post(
      process.env.VUE_APP_DOMAIN + '/rest/sales-package/partner',
      {
        partnerName: partnerName,
        partnerTypeId: partnerTypeId,
        website: website,
        countryId: countryId,
        commission: commission,
        currencyId: currencyId,
        ownerId: userId
      },
      config
    );
  }

  static async modifyPartner(
    partnerId,
    partnerName = null,
    partnerTypeId = null,
    website = null,
    countryId = null,
    commission = null,
    currencyId = null
  ) {
    const token = cookieService.getCookie('token');
    const config = {
      headers: {
        Authorization: token
      }
    };

    return await axios.put(
      process.env.VUE_APP_DOMAIN + '/rest/sales-package/partner',
      {
        partnerId: partnerId,
        partnerName: partnerName,
        partnerTypeId: partnerTypeId,
        website: website,
        countryId: countryId,
        commission: commission,
        currencyId: currencyId
      },
      config
    );
  }
}

const salesPackagePartnerService = SalesPackagePartnerService;
export default salesPackagePartnerService;
