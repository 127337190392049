import requestService from '../../requestService';
import cookieService from './../../cookieService';
import axios from 'axios';

export class SalesPackageCodeService {
  static async saveCodes(
    salesPackageId = null,
    codes = null,
    numberOfCodesToGenerate = null
  ) {
    const token = cookieService.getCookie('token');
    const config = {
      headers: {
        Authorization: token
      }
    };

    return await axios.post(
      process.env.VUE_APP_DOMAIN + '/rest/sales-package/code',
      {
        salesPackageId: salesPackageId,
        codes: codes,
        numberOfCodesToGenerate: numberOfCodesToGenerate
      },
      config
    );
  }

  static async checkCode(code, userId = null) {
    const path = '/rest/sales-package/code/check?code=' + code;

    return requestService.makeGetRequest(path);
  }

  static async getCodes(salesPackageId, codeType) {
    const path = '/rest/sales-package/code/list?outputFormat=csv&codeType=' + codeType + '&salesPackageId=' + salesPackageId;

    return requestService.makeGetRequest(path);
  }

  static async getNumberOfCodes(salesPackageId, codeType) {
    const path = '/rest/sales-package/code/number?codeType=' + codeType + '&salesPackageId=' + salesPackageId;

    return requestService.makeGetRequest(path);
  }
}

const salesPackageCodeService = SalesPackageCodeService;
export default salesPackageCodeService;
